<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
			<el-form ref="form" :inline="true"  :model="search_key" prop="">
				<div  style="position: absolute;right:20px;z-index: 999;">
					<el-button  type="primary" @click="handleExport">导出全部</el-button> 
				</div>
			    <el-form-item label="">
			        <el-input v-model="search_key.keyword" placeholder="输入编码/名称搜索" style="width:200px;"></el-input>
			    </el-form-item>
			    <el-button type="primary" @click="search()">查询</el-button>
			</el-form> 
			
			<el-table :data="tableData" border  :key="Math.random()">
				<el-table-column prop="location_name" label="所在仓库">
				</el-table-column>
				<el-table-column prop="mtl_code" label="物料编码">
				</el-table-column>
				<el-table-column prop="name" label="物料名称">
				</el-table-column>
				<el-table-column prop="cate_name" label="物料分类">
				</el-table-column>
				<!-- <el-table-column prop="receipt_number"  label="单价">
				</el-table-column> -->
				 <el-table-column header-align="center" label="">
					 <template slot="header" slot-scope="scope">
						 <el-tooltip class="item" effect="dark" content="结存库存计算为: 可用库存+冻结库存+入库数量-出库数量" placement="top">
								<span>初始(上月结存库存)<i class="el-icon-question" style="font-size:14px;padding-left:10px;"></i> </span>
						</el-tooltip>
					 </template>
					<el-table-column prop="init_number" label="初始数量" sortable> 
						<template slot-scope="scope">
							<span>{{scope.row.init_number?scope.row.init_number:'-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="init_unit_price" label="初始单价" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.init_unit_price?scope.row.init_unit_price:'-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="init_total_prices" label="初始金额" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.init_total_prices?scope.row.init_total_prices:'-'}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column header-align="center" label="入库">
					<el-table-column prop="in_number" label="入库数量" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.in_number?scope.row.in_number:'-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="in_unit_price" label="入库单价" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.in_unit_price?scope.row.in_unit_price:'-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="in_total_prices" label="入库金额" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.in_total_prices?scope.row.in_total_prices:'-'}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column header-align="center" label="出库">
					<el-table-column prop="out_number" label="出库数量" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.out_number?scope.row.out_number:'-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="out_unit_price" label="出库单价" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.out_unit_price?scope.row.out_unit_price:'-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="out_total_prices" label="出库金额" sortable>
						<template slot-scope="scope">
							<span>{{scope.row.out_total_prices?scope.row.out_total_prices:'-'}}</span>
						</template>
					</el-table-column>
				</el-table-column>
			 </el-table>
			 <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				tableData: [],
				currentPage:1,
				pagesize:10,
				total:0,
				search_key:{
					keyword:''
				}
			}
		},
		created() {
			this.getData()
		},
		mounted() {

		},
		methods: {
			getData() {
				this.$api.post('CseReport/outStorage', {
					page:this.currentPage-1,
					limit:this.pagesize,
					keyword:this.search_key.keyword
				}, res => {
					if (res.code == 200) {
						this.tableData = res.data.list
						this.total =  res.data.count		
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			search(){
				this.currentPage  = 1
				this.getData()
			},
			getCurrPage(val){   //获取当前页
			    this.currentPage =  val
			    this.getData()
			},
			getPageSize(val){   //获取每页条数
			    this.pagesize =  val
			     this.getData()
			},	
		
			handleExport(){
				location.href = '/api/admin/CseReport/exportOutStorage' 
			}
		},
	}
</script>
<style scoped>

</style>
